import PropTypes from "prop-types"
import React, {Component} from "react"

import Flourish from "../Flourish/Flourish"
import Heading from "../Heading/Heading"
import PreviewCompatibleImage from "../PreviewCompatibleImage"
import SubscribeForm from "../MailChimp/SubscribeForm"

import "./Hero.scss"


class SubscriptionHero extends Component {
  /*
  * Renders a subscription hero section
  */
  render () {
    const {
      id,
      heading=null,
      byline=null,
      content=null,
      modifiers=[],
      flourishes=[],
      image=null,
      mobileImage=null,
      link=null,
      children=null,
    } = this.props

    let heroClassModifiers = modifiers.map(
      modifier => "hero--" + modifier
    ).join(" ")

    heroClassModifiers += " hero--subscription"

    let hasExternalLink = link && link.location.includes("://")
    let linkTarget = hasExternalLink ? "_blank" : null
    let linkRel = hasExternalLink ? "noopener" : null

    return (
      <section id={id} className={`hero ${heroClassModifiers}`}>
        {flourishes ? flourishes.map((flourish, i) => <Flourish key={i} category={flourish}/>) : ""}
        <div className="hero__content">
          <div className="hero__text">
            {heading ? <Heading level={1} modifiers={byline ? ["leader"] : ["major"]}>{heading}</Heading> : ""}
            {byline ? <Heading level={0} modifiers={["hero-byline"]}>{byline}</Heading> : ""}
            <div className="hero__caption">{content ? content.split("\n\n").map((para, i) => <p key={i}>{para}</p>) : ""}</div>
            {children}
            <SubscribeForm/>
            {link && link.text ?
              <a className={`link link--${link.style}`} href={link.location} target={linkTarget} rel={linkRel}>{link.text}</a>
              : ""
            }
          </div>
          <div className={`hero__image`}>
            <PreviewCompatibleImage image={image} mobileImage={mobileImage}/>
          </div>
        </div>
      </section>
    )
  }
}


SubscriptionHero.propTypes = {
  id: PropTypes.string,
  heading: PropTypes.string,
  byline: PropTypes.string,
  content: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  flourishes: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  mobileImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  link: PropTypes.shape({
    text: PropTypes.string,
    location: PropTypes.string,
    style: PropTypes.string
  }),
  children: PropTypes.node
}


export default SubscriptionHero
