import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/Layout/Layout"
import SectionComponent from "../components/SectionComponent/SectionComponent"
import useSiteMetadata from "../components/SiteMetadata"


export const LandingPageTemplate = ({
  sections,
  helmet,
}) => {
  return (
    <div>
      {helmet || ""}
      {
        sections.map((section, index) => (
          <SectionComponent
            id={section.slug ? section.slug : `section-${index+1}`}
            key={index}
            {...section}
          />
        ))
      }
    </div>
  )
}

LandingPageTemplate.propTypes = {
  contentComponent: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.object),
  helmet: PropTypes.object,
}

const LandingPage = ({ data }) => {
  const { markdownRemark: page } = data
  const { siteUrl, googleSiteVerification } = useSiteMetadata()

  let ogImage = null
  if (page.frontmatter.ogImage){
    ogImage = page.frontmatter.ogImage.childImageSharp.gatsbyImageData
  }

  let title = page.frontmatter.title
  let pageTitle = (page.fields.slug === "/") ? title : `${title} | Kingston Labs`

  return (
    <Layout>
      <LandingPageTemplate
        sections={page.frontmatter.sections}
        helmet={
          <Helmet>
            <title>{pageTitle}</title>
            {page.fields.slug === "/" ?
              <meta name="google-site-verification" content={googleSiteVerification} />
              : ""
            }
            <meta
              property="og:title"
              content={`${page.frontmatter.title}`}
            />
            <meta
              name="description"
              content={`${page.frontmatter.description}`}
            />
            <meta
              property="og:description"
              content={`${page.frontmatter.description}`}
            />
            <meta property="og:url" content={`${siteUrl}${page.fields.slug}`}/>
            {ogImage ? <meta property="og:image" content={`${siteUrl}${ogImage.src}`}/> : ""}
            {ogImage ? <meta property="og:image:height" content={ogImage.height}/> : ""}
            {ogImage ? <meta property="og:image:width" content={ogImage.width}/> : ""}
            <meta property="og:url" content={`${siteUrl}/`}/>
          </Helmet>
        }
      />
    </Layout>
  )
}

LandingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }),
}

export default LandingPage

export const pageQuery = graphql`query LandingPageTemplate($id: String!) {
  markdownRemark(id: {eq: $id}) {
    fields {
      slug
    }
    frontmatter {
      title
      description
      ogImage {
        childImageSharp {
          gatsbyImageData(width: 1200, quality: 90, layout: FIXED)
        }
        extension
        publicURL
      }
      sections {
        slug
        heading
        byline
        content
        attribution
        subject
        link {
          text
          location
          style
        }
        image {
          childImageSharp {
            gatsbyImageData(quality: 90, layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
        mobileImage {
          childImageSharp {
            gatsbyImageData(quality: 90, layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
        layout
        style
        flourishes
        diagonal
        segments {
          visible
          heading
          subheading
          content
          link {
            text
            location
            style
          }
          image {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
          imageStyle
        }
      }
    }
  }
}`
