import PropTypes from "prop-types"
import React, {Component} from "react"

import Heading from "../Heading/Heading"
import Flourish from "../Flourish/Flourish"

import axs from "./logos/axs.svg"
import gd from "./logos/glasses-direct.svg"
import gr from "./logos/global-radio.svg"
import incopro from "./logos/incopro.svg"
import maxus from "./logos/maxus.svg"
import nomensa from "./logos/nomensa.svg"
import patch from "./logos/patch.svg"
import peloton from "./logos/peloton.svg"

import "./ClientLogos.scss"


class ClientLogos extends Component {
  /*
  * Renders a client logos section
  */
  render () {
    const {
      id,
      content,
      heading,
      byline,
      style="default",
      modifiers=[],
      flourishes=[],
      children=null
    } = this.props

    let styleModifiers = modifiers
    styleModifiers.push(style)
    let ClientLogosClassModifiers = styleModifiers.map(
      modifier => "client-logos--" + modifier
    ).join(" ")

    return (
      <section id={id} className={`client-logos ${ ClientLogosClassModifiers }`}>
        {flourishes ? flourishes.map((flourish, i) => <Flourish key={i} category={flourish}/>) : ""}
        <div className="client-logos__header">
          {heading ? <Heading level={1} modifiers={byline ? ["leader"] : ["major"]}>{heading}</Heading> : ""}
          {byline ? <Heading level={0} modifiers={["major"]}>{byline}</Heading> : ""}
          {content ? content.split("\n\n").map((para, i) => <p key={i}>{para}</p>) : ""}
        </div>
        <div className="client-logos__content">
          {children}
          <span className="client-logos__item">
            <img className="client-logos__image" src={patch} alt="Patch Plants"/>
          </span>
          <span className="client-logos__item">
            <img className="client-logos__image" src={gd} alt="Glasses Direct"/>
          </span>
          <span className="client-logos__item">
            <img className="client-logos__image" src={axs} alt="AXS"/>
          </span>
          <span className="client-logos__item">
            <img className="client-logos__image" src={incopro} alt="Incopro"/>
          </span>
          <span className="client-logos__item">
            <img className="client-logos__image" src={peloton} alt="Peloton"/>
          </span>
          <span className="client-logos__item">
            <img className="client-logos__image" src={gr} alt="Global Radio"/>
          </span>
          <span className="client-logos__item">
            <img className="client-logos__image" src={maxus} alt="Maxus"/>
          </span>
          <span className="client-logos__item">
            <img className="client-logos__image" src={nomensa} alt="Nomensa"/>
          </span>
        </div>
      </section>
    )
  }
}


ClientLogos.propTypes = {
  id: PropTypes.string,
  content: PropTypes.string,
  heading: PropTypes.string,
  byline: PropTypes.string,
  style: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  flourishes: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node
}


export default ClientLogos
